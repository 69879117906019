<template>
  <div class="container" ref="wrapper">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell v-for="(item, index) in articles" :key="item.id.toString()"
          @click="toDetail(item)" 
          >
            <div
              style="display: table-cell; vertical-align: middle;"
              class="zntitle van-multi-ellipsis--l3"
            >
              <div
                style="
                  float: left;
                  margin-right: 10px;
                  text-align: center;
                  font-size: 15px;
                  color: #6c6c6c;
                "
              >
                {{ index + 1 }}、
              </div>
              <h3
                style="
                  display: inline-block;
                  user-select: text;
                  -moz-user-select: text;
                  -webkit-user-select: text;
                  -ms-user-select: text;
                "
              >
              提交ID: {{ item.id }}
              </h3>
                        
            </div>

            

            <div style="float: right">{{ item.creationTime }}</div>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getAnswerList2 } from "@/api/questionnaire";
export default {
  name: "answer",
  props: {
    questionnaireId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      A3Error: false,
      A3Message: "去绑定A3工号",
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          asyncA3:-2,
          title: "",
          phone: "",
          questionnaireId: "",
        },
      },
    };
  },
  watch: {
    questionnaireId(val, oldVal) {
      //普通的watch监听
      if (val != oldVal) {
        this.onSearch();
      }
    },
  },

  activated() {
    this.page.filterData.questionnaireId = this.$route.query.id;
    //this.onSearch();
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.page.filterData.questionnaireId = this.$route.query.id;
  },

  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getAnswerList2({ ...this.page }); //({ ...this.page })
      if (!aresult.data.success) {
        Toast(aresult.data.errorMessage);
        this.upLoading = false;
        this.finished = true;
        if (aresult.data.errorCode == "A3Error1") {
          this.A3Error = true;
          this.A3Message = "去绑定A3工号";
        }
        if (aresult.data.errorCode == "A3Error2") {
          this.A3Error = true;
          this.A3Message = "去设置默认A3工号";
        }

        return;
      }
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    toBindA3() {
      this.$router.push({
        path: "/user/binda3",
      });
    },
    toDetail(item) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
      this.$router.push({
        path: "/question/answerdetail",
        query: { id: item.id},
      });
    },
    changeSorterType(typeid) {
      if (this.page.filterData.asyncA3 != typeid) {
        this.page.filterData.asyncA3 = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
